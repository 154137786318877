import React from "react";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import Layout from "../layout/Layout";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="payment-success">
        <div className="successicon">
          <img src="/assets/img/success-svgrepo-com.svg" alt="success" />
        </div>
        <h4>Payment Successful!</h4>
        <p>
          Thank you for your purchase. Your payment has been processed
          successfully.
        </p>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="btn btnUI-1"
        >
          Go to Home
        </button>
      </div>
    </Layout>
  );
};

export default PaymentSuccess;
